import invoke from 'lodash/invoke'
import VueRecaptcha from 'vue-recaptcha'
import lxAnalytics from '@/servicies-ts/analytics/LxAnalytics'

const reCaptchaRequired = ['prod'].includes(process.env.VUE_APP_MODE)

export default {
  name: 'lx-recaptcha',
  components: {
    VueRecaptcha
  },
  props: {
    type: String,
    lazy: Boolean,
  },
  data () {
    return {
      siteKey: process.env.VUE_APP_INVISIBLE_RECAPTCHA,
    }
  },
  async mounted () {
    if (!this.lazy) {
      this.loadCaptcha()
    }
  },
  methods: {
    async loadCaptcha () {
      if (reCaptchaRequired && !document.getElementById('recaptcha-script')) {
        await this.loadRecaptchaScript()
      }
      this.$emit('loaded')
    },
    onExpired () {
      invoke(this, '$refs.lxRecaptcha.$refs.recaptcha.reset')
    },
    loadRecaptchaScript () {
      return new Promise((resolve, reject) => {
        let script = document.createElement('script')
        script.id = 'recaptcha-script'
        script.src = 'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit'
        script.onerror = (e) => {
          lxAnalytics.send('recaptcha-script-load-error', { type: this.type })
          reject(e)
        }
        document.head.appendChild(script)
        const interval = setInterval(() => {
          if (document.querySelector('.grecaptcha-badge')) {
            clearInterval(interval)
            resolve()
          }
        }, 50)
      })
    },
  }
}
